<template>
    <div class="ma-10">
      <v-container>
        <v-row >
          <p class="mx-auto text-h4">Upgrade to Teacher Edition Full</p>
        </v-row>
        <v-row>
          <v-col>
            <v-card class="mx-auto" max-width="500">
              <v-img class="white--text align-end" height="200px" src="../../assets/homepage/teacher-stundets.jpg"></v-img>

              <v-card-title class="pb-0">
                Lee Lee teacher Edition
              </v-card-title>

              <v-card-text class="text--primary">
                <v-list three-line>
                  <v-list-item>
                    <v-list-item-avatar>
                      <img src="../../assets/admin/teaching-tools.svg" style="width: 40px; background-color: #01b9a5; height: 40px;">
                      <!-- <v-img src="../../assets/admin/teaching-tools.svg" width="50"></v-img> -->
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Teaching Tools</v-list-item-title>
                      <v-list-item-subtitle>LeeLee Teacher Edition brings the teacher a powerful pedagogical tool of literacy.</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- <v-divider inset></v-divider> -->
                </v-list>
              </v-card-text>

              
            </v-card>
          </v-col>
          <v-col>
            <v-card max-width="500" class="py-6">
              <p class="total text-center">User: {{ user.name}}</p>
              <p class="total text-center">Total : <strong>$ 63.00</strong></p>
              <p class="text-center">Subscripcion / year</p>
              <p class="text-center">By subscription, I agree to the <a target="_blank" href="/termsofservices" @click.stop>Terms of Service and Cancellation Policy</a></p>
              <v-card-actions>
                <v-btn color="green" width="100%" @click="Subscribirse" :loading="loading" :disabled="loading">
                  subscribe
                </v-btn>

              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>
<script>
import { functions,db } from '../../plugins/firebase'

export default {
  data() {
    return {
      user:{},
      loading: false,
    }
  },
  created () {
    
  },
  methods: {
    async GetDataTeacher() {
      let iud = this.$store.state.user.uid;
      let info = await db.collection("IndividualTeacher").doc(iud).get();
      console.log(info.data())
      this.user = { Email:info.data().Email, name:info.data().Name, id: iud }
    },
    async Subscribirse() {
      this.loading = true;
      console.log('dmeo')
      try {
        const createStripeCheckoutSubscription = functions.httpsCallable('createStripeCheckoutSubscription')
        const stripe = Stripe('pk_live_51IWTTYBCweBe746jpuhgLSXpjtmMcd0vk0zB2RJLu2yl9HVungx0TAu3WNiVZKrnAuvCOVPPGHwimE3Mt28g8kTB00t99XeYiM');
        await createStripeCheckoutSubscription()
          .then((response) => {
            let date = new Date();
            console.log(date.getTime())
            //let textData = date.toLocaleString('en-US');
            let textData =date.getTime()
            response.data.session.fecha = date.toLocaleString('en-US');
            const sessionId = response.data.session.id;
            var batch = db.batch();
            var step1 = db.collection('Logs').doc('DataLogs').collection('PaymentLogs').doc(this.user.id);
            batch.set(step1, {[`${[textData]}`]: response.data.session}, { merge: true });
            var step2 = db.collection('Logs').doc('DataLogs').collection('TokensPayment').doc(sessionId);
            batch.set(step2, {userUID: this.user.id});
            batch.commit().then(() => {
              stripe.redirectToCheckout({ sessionId: sessionId })
              setTimeout(() => this.loading = false, 3000);
            });
          })
      } catch (error) {
        console.log(error)
        this.loading = false;
      }
    }
  },
  mounted() {
    //let iud = this.$store.state.user;
    if (this.$store.state.user.uid == null){
      //console.log(uid)
      location.href = '/login';
    }
    this.GetDataTeacher();
  }
}
</script>
<style>
  .total{
    font-size: 1.5em;
  }
</style>